@import "mixins";
@import "variables";

html,
body {
    height: 100%;
}

body {
    background-image: url("../img/background/grey.png");
    font-family: $font-roboto;
}

h1,
.h1 {
    font-size: 1.4rem;
    color: $dark-theme;
}

h2,
.h2 {
    font-size: 1.3rem;
    color: $dark-theme;
}

h3,
.h3 {
    font-size: 1.2rem;
    color: $dark-theme;
}

h4,
.h4 {
    font-size: 1.1rem;
    color: $dark-theme;
}

a {
    color: $dark-theme;
    @include hover-focus-active() {
        color: $dark-theme;
    }
}

label {
    color: $dark-theme;
}
