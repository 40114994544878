@import "variables";

.nav-pills {
    .nav-link {
        color: $theme;
        &.active,
        &:active {
            color: $black !important;
            background-color: $light-orange !important;
        }
    }
}
