@mixin loopClasses ($prop, $classMap) {
    @each $name, $value in $classMap {
        &-#{$name} {
            #{$prop}: $value !important;
        }
    }
}

@mixin xyClasses($prop, $classMap){
    @each $name, $value in $classMap {
        &-x-#{$name} {
            #{$prop}-left: $value;
            #{$prop}-right: $value;
        }
    }
    @each $name, $value in $classMap {
        &-y-#{$name} {
            #{$prop}-top: $value;
            #{$prop}-bottom: $value;
        }
    }
}

$directions: (
    top:top,
    right:right,
    bottom:bottom,
    left:left
);

@mixin directionLoopClasses($prop, $classMap){
    @each $direction, $directionValue in $directions {
        &-#{$direction} {
            @include loopClasses(#{$prop}-#{$directionValue},$classMap);
        }
    }
    @include loopClasses(#{$prop},$classMap);
    @include xyClasses(#{$prop},$classMap);
}

@mixin squarize($size){
    width: $size;
    height: $size;
}

@mixin hover-focus-active() {
    &.active,
    &:hover,
    &:focus,
    &:active {
        @content;
    }
}
