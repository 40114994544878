@import "variables";
@import "mixins";
@import "~bootstrap/scss/mixins";

.btn-theme {
    @include button-variant($theme, $theme);
}

.btn-outline-theme {
    @include button-outline-variant($theme);
}

.btn-common {
    width: 150px;
}

.form-group{
    margin-bottom: 1rem;
}

.form-control {
    &:focus {
        border-color: $theme;
        box-shadow: none;
    }
}
.search-bar {
    position: sticky;
    top: 75px;
    z-index: $zindex-sticky;
}
