@import "variables";
@import "mixins";

.max-height {
    @include loopClasses(max-height, $maxHeight);
}

.width {
    @include loopClasses(width, $widthPx);
}

.max-width {
    @include loopClasses(max-width, $maxWidth);
}

.min-width-auto {
    min-width: auto !important;
}
