@import "variables";
@import "mixins";

.text {
    @include loopClasses(color, $colors);
}

.bg {
    @include loopClasses(background-color, $colors);
}

.box-with-shadow {
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06);
}

// Borders
.border {
    @include directionLoopClasses(border, $borders);
}
