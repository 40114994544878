@import "../variables";

.main-menu {
    .navbar-brand{
        margin-left: 1rem;
        margin-bottom: 0.75rem;
    }
    &.navbar-light .navbar-nav .nav-link {
        &:hover,
        &:focus {
            color: $theme;
        }
    }

    &.navbar-light .navbar-nav .show > .nav-link,
    &.navbar-light .navbar-nav .active > .nav-link,
    &.navbar-light .navbar-nav .nav-link.show,
    &.navbar-light .navbar-nav .nav-link.active {
        color: $theme;
        &:hover,
        &:focus {
            color: $theme;
        }
    }
}
