// Colors
$white: #fff;
$almost-white: #fcfcfc;
$light-white: #e7e7e7;
$black: #000;
$light-orange: #fdcc30;
$theme: #FDBA31;
$dark-theme: darken($theme, 20%);

$colors:(
    'white': $white,
    'almost-white': $almost-white,
    'light-white': $light-white,
    'light-orange': $light-orange,
    'sidebar': $light-orange,
    'theme': $theme,
    'dark-theme': $dark-theme
);

// Sizes
$widthPx:(
    80:80px,
    90:90px,
    100:100px
);

$maxWidth:(
    300:300px,
    400:400px
);
$maxHeight:(
    195:195px
);

// Borders
$borders:(
    'light-white': 1px solid $light-white
);

// Fonts
$font-roboto: 'Roboto Slab', serif;

$fonts:(
    roboto: $font-roboto
);
